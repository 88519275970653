<mat-card class="mat-elevation-z2 user-right-card" [ngClass]="{'approved': userRight && userRight.CrmStatusCode === CrmStatusCode.Approved}" (click)="goToApplication()" [ngStyle]="{'background-color':bgColor}">
  <mat-card-header>
    <img alt="app" mat-card-sm-image class="card-avatar" [src]="getImageSrc()" />
    <mat-card-title-group class="card-group">
      
      <mat-card-title *ngIf="userRight" [ngClass]="{'mobile': isMobile}" class="card-title">{{userRight.Application?.AppName | slice:0:50}}</mat-card-title>  
      <mat-card-subtitle *ngIf="userRight" [ngClass]="{'mobile': isMobile}" class="card-subtitle">{{userRight.Application?.Description | slice:0:112}}</mat-card-subtitle>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content class="card-content" *ngIf="userRight">
      {{userRight.Application?.Description | slice:0:112}}
  </mat-card-content>
  <mat-card-actions>
    <a *ngIf="userRight && userRight.Application?.TermsOfUse !== undefined" (click)="goToTermsOfUse()" class="card-action card-link">Terms of use</a>
    <a *ngIf="userRight && userRight.Application?.PrivacyPolicy !== undefined" (click)="goToPrivacyPolicy()" class="card-action card-link">Privacy policy</a>
    <span class="spacer"></span>
    <span *ngIf="userRight && userRight.CrmStatusCode === CrmStatusCode.Revoked" class="card-action">Access revoked</span>
    <span *ngIf="userRight && userRight.CrmStatusCode === CrmStatusCode.Denied" class="card-action">Access denied</span>
    <span *ngIf="userRight && userRight.CrmStatusCode === CrmStatusCode.ForApproval" class="card-action">Access requested</span>
    <button mat-button *ngIf="userRight && (userRight.CrmStatusCode === undefined || userRight.CrmStatusCode === CrmStatusCode.RequestableByUser)" class="menu-button" [ngClass]="{'mobile': isMobile}" (click)="requestAccess()">
      <!--<mat-icon class="menu-button-icon">badge</mat-icon>-->
      <span [ngClass]="{'mobile': isMobile}" class="card-action">Request access</span>
    </button>
  </mat-card-actions>
</mat-card>
