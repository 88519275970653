export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: 'cb493939-7069-42d6-a712-ab7d9eeb4b0c',
      scopes: ['cb493939-7069-42d6-a712-ab7d9eeb4b0c'],
    }
  },
  crmConnectorConfig: {
    scopes: ['https://erbeappsdev.onmicrosoft.com/crmconnector/UnifiedLoginRights.Read'],
    uri: 'https://crmconnectormik3cmon66ag2.azurewebsites.net'
  },
  b2cPolicies: {
    names: {
      signUpSignIn: "b2c_1a_unifiedlogin_signupsignin"
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://erbeappsdev.b2clogin.com/tfp/erbeappsdev.onmicrosoft.com/b2c_1a_unifiedlogin_signupsignin'
      }
    },
    authorityDomain: "erbeappsdev.b2clogin.com"
  },
  termsOfUse: 'https://en.erbe-med.com/fileadmin/user_upload/documents/PDF/Terms_of_use_EN.pdf',
  privacyPolicy: 'https://en.erbe-med.com/en-en/privacy-policy/'
};
